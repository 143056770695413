* {
  font-family: 'Outfit', 'Baloo';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: 'Outfit';
  src: url('./../fonts/Outfit-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Outfit Bold';
  src: url('./../fonts/Outfit-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Outfit ExtraBold';
  src: url('./../fonts/Outfit-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter Light';
  src: url('./../fonts/Inter-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Outfit Light';
  src: url('./../fonts/Outfit-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Outfit Regular';
  src: url('./../fonts/Outfit-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter Bold';
  src: url('./../fonts/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter Regular';
  src: url('./../fonts/Inter-Regular.ttf') format('truetype');
}

#badge-ribbon {
  margin-top: 100px;
  margin-left: 10px;
  position: relative;
  background: red;
  height: 70px;
  width: 70px;
  border-radius: 50px;
  transform: rotate(90deg);
}
#badge-ribbon:before,
#badge-ribbon:after {
  content: '';
  position: absolute;
  border-bottom: 60px solid red;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  top: 70px;
  left: -10px;
  transform: rotate(-140deg);
}
#badge-ribbon:after {
  left: auto;
  right: -10px;
  transform: rotate(140deg);
}
