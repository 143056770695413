.burger {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #222441;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  transition: all 0.5s ease;
  outline: 0;
  border: 0;
  margin-bottom: 10px;
}
.burger:hover {
  cursor: pointer;
  outline: 0;
  border: 0;
}
.burger:focus {
  outline: 0;
  border: 0;
}
.burger-strip {
  transition: all 0.5s ease;
}
.strip div {
  height: 3px;
  border-radius: 2px;
  background: white;
  margin: 2px;
  transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 20px;
}

.active .strip div {
  background: white;
}
/* BURGER 4 */
.active .burger-strip-4 div:last-child {
  width: 20px;
  transform: translateY(-7px) rotate(30deg);
  background-color: none;
}
.active .burger-strip-4 div:first-child {
  width: 20px;
  transform: translateY(7px) rotate(-30deg);
}
.active .burger-strip-4 div:nth-child(2) {
  width: 28px;
}
.active .burger-strip-4 {
  transform: scale(0.85);
}
.active {
  padding: 0px;
  background-color: none;
}
.active div {
  margin: 10px;
}
.menu-overlay {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.15);
  flex-grow: 1;
  flex-direction: column;
  gap: 30px;
  padding: 0;
  align-items: center;
  z-index: 50;
  transition: width 0.7s ease;
}
.active-menu {
  padding: 20px 10px;

  width: 100vw;
}
